import { useState, useEffect } from 'react'
import { Skeleton } from '../Skeleton'

const CarouselSkeleton = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  if (!isVisible) {
    return null
  }

  return (
    <div className="flex h-full w-screen items-stretch gap-[10px]">
      <Skeleton className="h-[400px] w-[225px]" />
      <Skeleton className="h-[400px] w-[225px]" />
      <Skeleton className="h-[400px] w-[225px]" />
      <Skeleton className="h-[400px] w-[225px]" />
    </div>
  )
}

export default CarouselSkeleton

import axios, { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { urlParams } from '../states'
import { StoryRecord } from '../types'
import { config } from '@/config'

interface IStoryParams {
  safeEditorial: string
}

export const fetchStories = async ({ safeEditorial }: IStoryParams) => {
  const restApiEndpoint = config.api.apiBaseUrl
  const response: AxiosResponse<StoryRecord[]> = await axios.get(`${restApiEndpoint}/story/published/${safeEditorial}`)
  return response.data
}

export const useGetStories = () => {
  const { editorial } = useAtomValue(urlParams)
  const safeEditorial = editorial ?? 'isport'

  return useQuery<StoryRecord[], AxiosError>({
    queryKey: ['stories', safeEditorial],
    queryFn: () => fetchStories({ safeEditorial }),
    refetchOnWindowFocus: false,
  })
}

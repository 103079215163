import { PageRecord, StoryRecord } from '@/types'
import { sendToAnalytics, sendToParent } from '..'

// page navigation event
export const handlePlayerPageNavigationEvent = (event: CustomEvent, page: PageRecord) => {
  const data = {
    event: 'story_page_navigation',
    page_id: event.detail.pageId,
    page_title: page.text,
    published_at: page.published,
    cta_url: page.link,
    story_progress: event.detail.progress,
  }
  sendToAnalytics({ ...data })
  sendToParent(data)
}

// story navigation event
export const handlePlayerStoryProgressEvent = (event: CustomEvent) => {
  const data = {
    event: 'story_progress_navigation',
    current_story: event.detail.index,
    remaining_stories: event.detail.remaining,
  }
  sendToAnalytics({ ...data })
  sendToParent(data)
}

// open CTA link event
export const handleOpenAttachmentGtmEvent = () => {
  const data = {
    event: 'story_open_cta_link',
  }
  sendToAnalytics({ ...data })
  sendToParent(data)
}

// carousel click event
export const handleAmpPlayerOpenStoryEvent = (story: StoryRecord) => {
  const data = {
    event: 'story_open_modal',
    story_id: story.id,
    story_title: story.title,
    number_of_pages: story?.pages?.length,
    editorial: story.editorial,
  }
  sendToAnalytics({ ...data })
  sendToParent(data)
}

// close story event
export const handleCloseModalEvent = () => {
  const data = {
    event: 'story_close_modal',
  }
  sendToAnalytics({ ...data })
  sendToParent(data)
}

type ConfigType = {
  api: {
    baseUrl: string
    imagesBaseUrl: string
    apiBaseUrl: string
  }
  app: {
    debug: boolean
  }
}

const apiBaseUrl = import.meta.env.VITE_STORY_BASE_URL
const mediaBaseUrl = import.meta.env.VITE_MEDIA_BASE_URL

export const config: ConfigType = {
  api: {
    baseUrl: `${apiBaseUrl}/admin/api/v1/`,
    imagesBaseUrl: `${mediaBaseUrl}/`,
    apiBaseUrl,
  },
  app: {
    debug: false,
  },
}

import { useContext, useEffect, useRef, useCallback } from 'react'
import { AmpContext } from '@/context/ampContext'
import {
  handleCloseModalEvent,
  handleOpenAttachmentGtmEvent,
  handlePlayerPageNavigationEvent,
  handlePlayerStoryProgressEvent,
} from '@/analytics/events'
import { useGetStories } from './useGetStories'

export interface AmpStoryPlayer {
  isReady: boolean
  add: (stories: { href: string; title: string }[]) => void
  show: (href: string, pageId: string, options?: { animate: boolean }) => void
  play: () => void
  pause: () => void
  rewind: () => void
  getStories: () => { href: string; id: number }[]
  addEventListener: (type: string, listener: (event: CustomEvent) => void) => void
  removeEventListener: (type: string, listener: (event: CustomEvent) => void) => void
  current: AmpStoryPlayer | null
}

const useAmpPlayer = () => {
  const { currentStory, setCurrentStory, currentSlide, setCurrentSlide, setIsReady, setIsFullScreen } = useContext(AmpContext)

  const { data, isPending, isError } = useGetStories()

  const ampContainerRef = useRef<HTMLElement | null>(null)
  const playerRef = useRef<AmpStoryPlayer | null>(null)

  const handleNavigation = useCallback((e: CustomEvent) => {
    const { index, remaining } = e.detail
    handlePlayerStoryProgressEvent(e)

    const shadowRoot = document.querySelector('amp-story-player')?.getElementsByTagName('div')?.[0]?.shadowRoot

    const prevButton = shadowRoot?.querySelector('.i-amphtml-story-player-panel-prev') as HTMLButtonElement
    const nextButton = shadowRoot?.querySelector('.i-amphtml-story-player-panel-next') as HTMLButtonElement
    const shouldHideInitially = window.innerWidth < 1000

    if (prevButton) {
      prevButton.style.opacity = index === 0 || shouldHideInitially ? '0' : '1'
      prevButton.style.pointerEvents = index === 0 || shouldHideInitially ? 'none' : 'auto'
    }

    if (nextButton) {
      nextButton.style.opacity = remaining === 0 || shouldHideInitially ? '0' : '1'
      nextButton.style.pointerEvents = remaining === 0 || shouldHideInitially ? 'none' : 'auto'
    }
  }, [])

  const handleStoryProgress = useCallback(
    (e: CustomEvent) => {
      if (currentStory === null) return
      if (isPending || isError) return

      const stories = data

      const findPage = stories[currentStory]?.pages?.find(page => page.id === e.detail.pageId)

      if (!findPage) return

      handlePlayerPageNavigationEvent(e, findPage)
    },
    [currentStory, data, isPending, isError]
  )

  const handlePlayerClose = useCallback(() => {
    playerRef.current?.pause()
    setCurrentStory(null)
    handleCloseModalEvent()
  }, [setCurrentStory])

  useEffect(() => {
    ampContainerRef.current = document.getElementById('story-container')
    playerRef.current = document.querySelector('.story-player') as unknown as AmpStoryPlayer

    if (playerRef.current) {
      setIsReady(playerRef.current.isReady)
    } else {
      setIsReady(false)
    }
  }, [setIsReady])

  useEffect(() => {
    const ampContainer = ampContainerRef.current
    const player = playerRef.current

    if (!player || !ampContainer || !player.isReady) return

    const stories = player.getStories()

    if (currentStory != null) {
      const currentStoryObj = stories[currentStory]
      if (!currentStoryObj) return

      setIsFullScreen(true)
      ampContainer.classList.remove('closed')
      player.show(currentStoryObj.href, '', { animate: true })
      player.play()
      window.parent.postMessage({ type: 'requestFullscreen' }, '*')
    } else {
      setIsFullScreen(false)
      ampContainer.classList.add('closed')
      window.parent.postMessage({ type: 'requestNormalScreen' }, '*')
    }
  }, [currentStory, setIsFullScreen])

  useEffect(() => {
    const ampPlayer = playerRef.current
    if (!ampPlayer) return

    ampPlayer.addEventListener('amp-story-player-close', handlePlayerClose)

    ampPlayer.addEventListener('storyNavigation', handleStoryProgress)

    ampPlayer.addEventListener('navigation', handleNavigation)

    ampPlayer.addEventListener('page-attachment-open', handleOpenAttachmentGtmEvent)

    return () => {
      ampPlayer.removeEventListener('amp-story-player-close', handlePlayerClose)

      ampPlayer.removeEventListener('page-attachment-open', handleOpenAttachmentGtmEvent)

      ampPlayer.removeEventListener('storyNavigation', handleStoryProgress)

      ampPlayer.removeEventListener('navigation', handleNavigation)
    }
  }, [handlePlayerClose, handleNavigation, handleStoryProgress])

  return {
    currentStory,
    currentSlide,
    setCurrentSlide,
    playerRef,
  }
}

export default useAmpPlayer

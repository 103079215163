import { StoryRecord } from '@/types'
import PlaceholderV2 from '@/assets/placeholder-img.png'
import { Play } from 'lucide-react'
import { getStoryAssetImageUrl } from '@/utils/url'
import ImageWithPlaceholder from '@/components/ImageWithPlaceholder'

type Props = {
  story: StoryRecord
  onClick: (id: number, story: StoryRecord) => void
  index: number
}

export const PagePreview = ({ story, onClick, index }: Props) => {
  if (!story.pages || story.pages.length === 0) return null
  const previewPage = story.pages[0]
  const hasImage = !!previewPage.imageS3Key || !!previewPage.coverS3Key
  const imageUrl = hasImage ? getStoryAssetImageUrl(previewPage.coverS3Key || previewPage.imageS3Key || '') : PlaceholderV2

  return (
    <div
      className="group relative box-border h-[375] w-[215px] select-none overflow-clip rounded-xl border-[2px] border-transparent text-white shadow-sm transition-all ease-in-out hover:scale-105 hover:border-[#C82322] hover:text-[#C82322]"
      onClick={() => onClick(index, story)}
    >
      {previewPage.coverS3Key && (
        <div className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 cursor-pointer opacity-0  transition-all ease-in-out group-hover:opacity-100">
          <div className="flex size-12 items-center justify-center rounded-full bg-[#c82222d6]">
            <Play color="white" fill="white" size={18} />
          </div>
        </div>
      )}
      <div className="h-full w-full">
        <ImageWithPlaceholder loading="lazy" src={imageUrl} placeholderSrc={PlaceholderV2} alt="story" className="rounded-md" />
      </div>

      {(previewPage.emoji || previewPage.text) && (
        <div className="absolute bottom-0 flex h-full w-full  cursor-pointer flex-col justify-end gap-2 rounded-sm bg-gradient-to-t from-black from-5% via-transparent via-40% p-4">
          <p className="text-4xl">{previewPage.emoji}</p>
          <p
            className="font-roboto text-lg font-bold uppercase leading-6 tracking-wide group-hover:underline group-hover:underline-offset-2"
            style={{ wordBreak: 'break-word' }}
          >
            {previewPage.text}
          </p>
        </div>
      )}
    </div>
  )
}

export default PagePreview

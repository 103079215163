import { StoryRecord } from '@/types'
import PagePreview from '../PagePreview'
import { useContext, useEffect } from 'react'
import { createAmp } from '@/utils/amp'
import { ChevronLeft, ChevronRight } from 'lucide-react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/virtual'
import { SwiperOptions } from 'swiper/types'
import useAmpPlayer from '@/hooks/useAmpPlayer'
import { cn } from '@/utils/cn'
import { AmpContext } from '@/context/ampContext'
import { handleAmpPlayerOpenStoryEvent } from '@/analytics/events'
import CarouselSkeleton from '../CarouselSkeleton'

type Props = {
  collection: StoryRecord[]
}

const swiperOptions: SwiperOptions = {
  navigation: {
    nextEl: '.image-swiper-button-next',
    prevEl: '.image-swiper-button-prev',
    disabledClass: 'swiper-button-disabled',
  },
  modules: [Navigation],
  slidesPerView: 'auto',
  simulateTouch: false,
}

export const Carousel = ({ collection }: Props) => {
  const { setCurrentSlide, currentSlide, playerRef } = useAmpPlayer()
  const { isFullScreen, setCurrentStory: setStory, isReady } = useContext(AmpContext)

  useEffect(() => {
    const ampPlayer = playerRef.current
    if (ampPlayer && ampPlayer.getStories().length === 0) {
      const pageIds = collection.map(story => story.id)
      createAmp(pageIds, ampPlayer)
    }
  }, [collection, playerRef])

  const handleClick = (id: number, story: StoryRecord) => {
    setStory(id)
    handleAmpPlayerOpenStoryEvent(story)
  }

  if (!isReady) return <CarouselSkeleton />

  return (
    <div className={cn('relative flex w-screen items-center overflow-hidden', isFullScreen && 'hidden')}>
      <div className="swiper-button image-swiper-button-prev absolute z-10 cursor-pointer transition-all hover:scale-110">
        <div className="flex h-12 w-8 items-center justify-center bg-[#EEEEEE]">
          <ChevronLeft className="size-6 opacity-50" />
        </div>
      </div>
      <Swiper
        {...swiperOptions}
        onSlideChange={e => setCurrentSlide(e.activeIndex)}
        initialSlide={currentSlide}
        className="!m-1 !flex !items-center"
      >
        {collection.map((story, id) => {
          return (
            <SwiperSlide className="!flex !h-[400px] !w-[225px] !items-center !justify-center" key={story.id}>
              <PagePreview story={story} index={id} onClick={handleClick} />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="swiper-button image-swiper-button-next absolute right-0 z-50 cursor-pointer transition-all hover:scale-110">
        <div className="flex h-12 w-8 items-center justify-center bg-[#EEEEEE]">
          <ChevronRight className="size-6 opacity-50" />
        </div>
      </div>
    </div>
  )
}

export default Carousel

import { config } from '../../config'

export const getUrlParams = () => {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const editorial = params.get('editorial')
  return { editorial }
}

export const getStoryAssetImageUrl = (s3Key: string) => `${config.api.imagesBaseUrl}${s3Key}`

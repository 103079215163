import { cn } from '@/utils/cn'
import React, { useState } from 'react'
import { Skeleton } from '../Skeleton'

type ImageWithPlaceholderProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  placeholderSrc?: string
}

const ImageWithPlaceholder = ({ src, placeholderSrc, alt, className = '', ...props }: ImageWithPlaceholderProps) => {
  const [imageStatus, setImageStatus] = useState<'loading' | 'loaded' | 'error'>('loading')

  const handleLoad = () => setImageStatus('loaded')
  const handleError = () => setImageStatus('error')

  return (
    <div className="relative h-[375px] w-[215px]">
      {imageStatus === 'loading' && (
        <div className="absolute inset-0">
          <Skeleton className="h-full w-full" />
        </div>
      )}
      {imageStatus !== 'error' && (
        <img
          {...props}
          src={src}
          alt={alt}
          onLoad={handleLoad}
          onError={handleError}
          className={cn(className, 'h-full w-full object-cover', imageStatus === 'loaded' ? 'block' : 'invisible')}
        />
      )}
      {imageStatus === 'error' && placeholderSrc && (
        <img src={placeholderSrc} alt={alt} className={`${className} h-full w-full object-cover`} />
      )}
    </div>
  )
}

export default ImageWithPlaceholder

import { config } from '@/config'
import { AmpStoryPlayer } from '@/hooks/useAmpPlayer'

export const createAmp = (pageIds: string[], player: AmpStoryPlayer) => {
  const cont = document.getElementById('story-container')
  if (!cont) return
  const list = pageIds.map(id => {
    return { href: `${config.api.apiBaseUrl}/story/${id}`, title: id }
  })
  player.add(list)
}

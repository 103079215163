export const sendToAnalytics = (eventData: DataLayerEvent) => {
  window.cncStoriesDataLayer = window.cncStoriesDataLayer || []
  window.cncStoriesDataLayer.push(eventData)
}

export const sendToParent = (eventData: DataLayerEvent) => {
  if (window.parent !== window) {
    window.parent.postMessage(eventData, '*')
  } else {
    console.warn('Cannot send message to parent: Not inside an iframe.')
  }
}
